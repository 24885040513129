/* eslint-env jquery */
/* global debounce */
require( 'slick-carousel-latest' );

export const getCurrentSlickBreakpoint = () => {
  const viewportWidth = document.body.getBoundingClientRect().width;
  // eslint-disable-next-line no-undef
  const slickBreakPoints = JSON.parse( SLICK_SLIDER_BREAKPOINTS );
  let currentSlickBreakpoint;
  let smallestBreakpoint = Math.max( ...Object.values( slickBreakPoints ) );
  Object.keys( slickBreakPoints ).forEach( breakpoint => {
    if ( viewportWidth < slickBreakPoints[breakpoint] && slickBreakPoints[breakpoint] <= smallestBreakpoint ) {
      smallestBreakpoint = slickBreakPoints[breakpoint];
      currentSlickBreakpoint = breakpoint;
    }
  } );
  return currentSlickBreakpoint;
};

( function ( $, w ) {
  let windowWidth = $( w ).width();

  const initSlick = () => {
    const currentSlickBreakpoint = getCurrentSlickBreakpoint();
    window.currentSlickBreakpoint = currentSlickBreakpoint;

    if ( $( '[data-slick]' ).length ) {
      if ( currentSlickBreakpoint ) {
        $( '[data-slick]' )
          .not( `[data-unslick-${currentSlickBreakpoint}]` )
          .not( '.bs-slider-accordion-slick' )
          .not( '.slick-initialized' )
          .slick();
      } else {
        $( '.bs-section--home-tab-slider [data-slick]' ).each( ( i, slck ) => { $( slck ).pauseOnHoverFalse(); } );
        $( '[data-slick]' ).not( '.bs-slider-accordion-slick' ).not( '.slick-initialized' ).slick();
      }
    }
  };

  const autoPlaySpeedFunction = () => {
    $( '.bs-tab-slider--progress-bar :not(.bs-slider-content)[data-slick]' ).each( ( index, ele ) => {
      if ( $( ele ).hasClass( 'slick-initialized' ) ) {
        const slick = $( ele ).slick( 'getSlick' );

        //append wrapper class and animator span to each slick slide element
        $.each( $( ele ).find( '.slick-slide' ), ( i, element ) => {
          const animatorElement = $( element ).find( '.slick-slide-wrapper .slick-slide-animator' );
          animatorElement.unwrap();
          animatorElement.remove();

          $(element).find('.slick-slide-wrapper').children().wrapAll('<div class="slick-slide-title-wrapper"></div>'); // eslint-disable-line
          const $content = $( element ).find( '.slick-slide-title-wrapper' );
          $content.append( '<span class="slick-slide-animator"></span>' );
        } );
        //set animator animation-duration
        if ( slick.options.autoplay === true ) {
          const playSpeed = slick.options.autoplaySpeed;
          $( ele )
            .find( '.slick-slide-animator' )
            .attr( 'data-speed', `${playSpeed}ms` )
            .css( 'animation-duration', `${playSpeed}ms` );
        }
      }
    } );
  };

  // To refresh the initiated slick sliders
  const refreshSlick = () => {
    $( '.slick-initialized' ).each( function ( key ) {
      if ( $( '.slick-initialized' )[key]?.slick ) {
        $( '.slick-initialized' )[key].slick.refresh();
      }
    } );
  };

  // To reset the autoplay enabled sliders to the initial slide when appearing on the viewport
  const resetSlidersWithIntersectionObserver = () => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0,
    };

    const io = new IntersectionObserver( entries => {
      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          $.each( $( '.slick-initialized' ), ( index, element ) => {
            const slickSlider = $( element );
            const slickObject = slickSlider.slick( 'getSlick' );
            const initialSlide = slickObject.options.initialSlide;
            // Resetting only if the current slide is greater than the initial slide
            if ( slickObject.options.autoplay && slickObject.currentSlide > initialSlide ) {
              slickSlider.slick( 'slickGoTo', initialSlide );
            }
          } );
        }
      } );
    }, options );

    $.each( $( '.bs-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
    $.each( $( '.bs-tab-slider' ).closest( '.bs-section' ), ( index, section ) => {
      io.observe( section );
    } );
  };

  const debouncedHandlers = () => {
    const dynamicWidth = $( w ).width();
    if ( windowWidth !== dynamicWidth ) {
      initSlick();
      autoPlaySpeedFunction();
      refreshSlick();
      windowWidth = dynamicWidth;
    }
  };

  // To slide between first slide and last slide smoothly when the 'Infinite' mode is enabled
  const slider = $( '[data-slick]' );
  slider.on( 'init reinit', () => {
    // eslint-disable-next-line no-undef
    FancyappsUi.bind( '[data-fancybox]', {
      on: {
        closing:() => {
          $( '.bs-section--home-tab-slider .slick-slider' ).slick( 'slickPlay' );
        },
        done:() => {
          $( '.bs-section--home-tab-slider .slick-slider' ).slick( 'slickPause' );
          
        }
      },
    } );
  } )
    .on( 'beforeChange', ( e, slick, currentSlide, nextSlide ) => {
      
      slick.$slides
        .find( 'video' )
        .each( ( ind,video ) => {
          $( video )[0].pause();
        } );

      if ( slick.options.infinite ) {
        const slidesCount = slick.$slides.length,
          cls = 'slick-current slick-active' + ( slick.options.centerMode ? ' slick-center' : '' );
        let selector = null;
        if ( nextSlide === 0 ) {
          selector = `.slick-slide[data-slick-index="0"], .slick-slide[data-slick-index="${slidesCount}"]`;
        } else if ( nextSlide === slidesCount - 1 ) {
          selector = `.slick-slide[data-slick-index="-1"], .slick-slide[data-slick-index="${slidesCount - 1}"]`;
        }

        if ( !selector ) return;
        // Adding a timeout since we need to add the active class after the transition has started
        setTimeout( () => {
          $( '.slick-slide', slick.$slider ).removeClass( cls );
          $( selector, slick.$slider ).addClass( cls );
        }, 10 );
      }
      if ( slick.options.autoplay === true ) $( e.target ).slick( 'slickSetOption', 'pauseOnFocus', false, false );
      $( e.target ).addClass( 'bs-slide-arrows-hide' );
      setTimeout( () => {
        $( e.target ).removeClass( 'bs-slide-arrows-hide' );
      }, 1450 );
    } )
    .on( 'afterChange', ( e, slick, currentSlide ) => {
      if ( slick.$slides.eq( currentSlide ).find( 'video' ).length > 0 ) {
        slick.$slides.eq( currentSlide )
          .find( 'video' )[0].play();
      }
    } );

  // Calls when the window is fully loaded
  $( w ).on( 'load', () => {
    initSlick();
    autoPlaySpeedFunction();
    resetSlidersWithIntersectionObserver();
  } );

  // Calls on window resize
  $( w ).on( 'resize', debounce( debouncedHandlers, 500 ) );
  // add pauseOnHover to false 
  $.fn.pauseOnHoverFalse = function ( options ) {
    const configs = $.extend(
      {
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      options
    );
    const currSlider = this,
      currSlickData = currSlider.data( 'slick' );
    // eslint-disable-next-line array-callback-return
    Object.values( currSlickData.responsive ).map( ( point ) => {
      if ( point.settings !== 'unslick' ) {
        point.settings.pauseOnHover = configs.pauseOnHover;
        point.settings.pauseOnFocus = configs.pauseOnFocus;
      }
    } );
    currSlider.data( 'slick', currSlickData );
  };
} )( jQuery, window );
